import React, { useEffect, useState } from "react";
import './App.css';

const Goose = () => {
  const [position, setPosition] = useState(0);
  const speed = 2;

  useEffect(() => {
    const moveGoose = () => {
      setPosition((prev) => (prev > window.innerWidth ? 0 : prev + speed));
    };
    const interval = setInterval(moveGoose, 20);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="goose" style={{ left: position }}>
      🪿
    </div>
  );
};

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <button onClick={() => copyToClipboard(text)}>
      {copied ? '☑️' : '📄'}
    </button>
  );
};

const Status = () => {
  const running = "operational";
  const down = "down"
  const [status, setStatus] = useState(running);
  const [statusColor, setStatusColor] = useState('#2fcc66');

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await fetch('/api/ping');
        const data = await response.json();
        console.log(data);
        if (data.message === 'pong') {
          setStatus(running);
          setStatusColor('#2fcc66');
        } else {
          setStatus(down);
          setStatusColor('#e74b3c');
        }
      } catch (error) {
        setStatus(down);
        setStatusColor('#e74b3c');
      }
    };

    checkStatus();
  }, []);

  return (
    <h2 className="headpad" style={{backgroundColor: statusColor, borderRadius: "3px", color: "white"}}>
      API is {status}
      <span style={{float: "right"}}></span>
    </h2>
  );
};

const ScrollComponent = ({ children }) => {
  return (
    <div className="scroll-container">
      {children.map((child, index) => (
        <div key={index} className="scroll-block">
          {child}
        </div>
      ))}
    </div>
  );
};

const SomeComponent = ({ title, content }) => {
  return (
    <div>
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

function HelloWorld() {
  const [globe, setGlobe] = useState('🌍');

  useEffect(() => {
    const interval = setInterval(() => {
      setGlobe((prevGlobe) => (prevGlobe === '🌍' ? '🌎' : '🌍'));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      <div className="globe">{globe}</div>
    </div>
  );
}

const PyTorch = () => {
  return (
      <img
        src="https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://pytorch.org&size=180"
        alt="PyTorch Logo"
        className="logo"
        // style={{  transform: "translateY(3px)" }}
      />
);
}

const LightGBM = () => {
  return (
      <img
        src="https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://lightgbm.readthedocs.io/en/stable/&size=180"
        alt="LightGBM Logo"
        className="logo"
      />
);
}

const Cplusplus = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/213px-ISO_C%2B%2B_Logo.svg.png"
        alt="LightGBM Logo"
        className="logo"
      />
);
}

const CUDA = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/en/b/b9/Nvidia_CUDA_Logo.jpg"
        alt="LightGBM Logo"
        className="logo"
      />
);
}

const C = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/The_C_Programming_Language_logo.svg/230px-The_C_Programming_Language_logo.svg.png"
        alt="LightGBM Logo"
        className="logo"
      />
);
}

const CMake = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/CMake_logo.svg/240px-CMake_logo.svg.png"
        alt="LightGBM Logo"
        className="logo"
      />
);
}


const Python = () => {
  return (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/219px-Python-logo-notext.svg.png"
        alt="LightGBM Logo"
        className="logo"
        style={{  transform: "translateY(6.3px)" , height: "1.2em"}}
      />
);
}

const Pytest = () => {
  return (
      <img
        src="https://docs.pytest.org/en/stable/_static/favicon.png"
        alt="LightGBM Logo"
        className="logo"
      />
);
}

const CopyToClipboard = ({ text }) => {
  const [backgroundColor, setBackgroundColor] = useState('#cececd');
  const [copied, setCopied] = useState(false);
  const [buttonText, setButtonText] = useState('Copy');
  
  const copyText = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setButtonText('Copied!');
    setTimeout(() => setCopied(false), 3000);
    setTimeout(() => setButtonText('Copy'), 3000);
  };

  return (
    <div className="copy-container">
      <code>{text}</code>
      <button onClick={copyText} className="copy-button">
        {buttonText}
      </button>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <header>
        <h1>Ryan Moore</h1>
      </header>

      <h2 className="headpad">Open source</h2>
      <div>
        <a href="https://github.com/yrmo/cudagrad">cudagrad</a> <CUDA/><Cplusplus/><CMake/><Python/><p>CUDA C++ strided float tensor automatic differentiation engine with Python bindings</p>
          <a href="https://github.com/yrmo/engine.c">engine.c</a> <C/><Python/><p>Reimplementation of Karpathy's scalar-valued autograd engine as a Python C extension (9x speedup)</p>
      </div>

      <h2 className="headpad">Kaggle notebooks</h2>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th style={{textAlign:"left"}}>Competition</th>
              {/* <th style={{textAlign:"left"}}>My thoughts</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><LightGBM/></td>
              <td><a href="https://www.kaggle.com/code/yrmoore/single-lightgbm-model">Playground Series S4E8</a></td>
              <td>Top 25% LightGBM model, overfit public leaderboard</td>
            </tr>
            <tr>
              <td><PyTorch/></td>
              <td><a href="https://www.kaggle.com/code/yrmoore/pytorch-mnist-cnn">Digit Recognizer</a></td>
              <td>0.99+ MNIST</td>
            </tr>
            <tr>
              <td><PyTorch/></td>
              <td><a href="https://www.kaggle.com/code/yrmoore/pytorch-regression">House Prices Advanced Regression Techniques</a></td>
              <td>Categorical data, tricky feature engineering</td>
            </tr>
            <tr>
              <td><PyTorch/></td>
              <td><a href="https://www.kaggle.com/code/yrmoore/simple-pytorch-mlp">Titanic</a></td>
              <td>0.80143 with a very tiny model</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Status/>
      <div>
          <CopyToClipboard text="curl https://yrmo.ca/api/ping"/>
          <CopyToClipboard text='curl "http://yrmo.ca/api/models/xor?a=0&b=1"'/>
      </div>

      <h2 className="headpad">About me</h2>
      <p>
        I've been interested in Python C/C++ extensions recently. I'm trying to win Kaggle competitions. I work as a <s>research assistant</s> <s>data analyst</s> DBA @ <s>Royal Military College of Canada</s> <s>Queen's University</s> Sun Life.
      </p>

      <footer style={{paddingBottom: "25px"}}>
        <p>Question? <a href="mailto:ryanm.inbox@gmail.com?subject=You're awesome and I had to tell you">Feel free to reach out!</a> <div className="wave">👋</div></p>
      </footer>
    </div>
  );
}

export default App;
